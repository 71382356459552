function importAll(r) {
	console.log("r",r);
	let images = [];
	r.keys().map((item, index) => {  images.push(r(item).default); });
	return images;
}

const getScreens = (number,market, subVersion, language) => {

	if(number === '01' &&  subVersion === "00" && language === 'english') {
		return importAll(require.context(`../version01/00/english`, false, /\.(jpg|png)$/));
	} else if(number === '01' && subVersion === "00" &&  language === 'bengali') {
		return importAll(require.context('../version01/00/bengali', false, /\.(jpg|png)$/));
	} else if(number === '01' && subVersion === "00" &&  language === 'spanish') {
		return importAll(require.context('../version01/00/spanish', false, /\.(jpg|png)$/));
	} else if(number === '01' && subVersion === "01" &&  language === 'english') {
		return importAll(require.context('../version01/01/english', false, /\.(jpg|png)$/));
	} else if(number === '01' && subVersion === "01" &&  language === 'bengali') {
		return importAll(require.context('../version01/01/bengali', false, /\.(jpg|png)$/));
	} else if(number === '01' && subVersion === "01" &&  language === 'spanish') {
		return importAll(require.context('../version01/01/spanish', false, /\.(jpg|png)$/));
	} else if(number === '02' &&  subVersion === "00" && language === 'english') {
		return importAll(require.context('../version02/00/english', false, /\.(jpg|png)$/));
	} else if(number === '02' &&  subVersion === "00" && language === 'bengali') {
		return importAll(require.context('../version02/00/bengali', false, /\.(jpg|png)$/));
	} else if(number === '02' &&  subVersion === "00" && language === 'spanish') {
		return importAll(require.context('../version02/00/spanish', false, /\.(jpg|png)$/));
	} else if(number === '03' &&  subVersion === "00" && language === 'english') {
		return importAll(require.context('../version03/00/english', false, /\.(jpg|png)$/));
	} else if(number === '04' &&  subVersion === "00" && language === 'english') {
		return importAll(require.context('../version04/00/english', false, /\.(jpg|png)$/));
	}	else if(number === '05' &&  subVersion === "00" && language === 'english') {
		return importAll(require.context('../version05/00/english', false, /\.(jpg|png)$/));
	}	else if(number === '05' &&  subVersion === "01" && language === 'english') {
		return importAll(require.context('../version05/01/english', false, /\.(jpg|png)$/));
	}	else if(number === '06' &&  subVersion === "00" && language === 'english') {
		return importAll(require.context('../version06/00/english', false, /\.(jpg|png)$/));
	} else if(number === '07' &&  subVersion === "00" && language === 'english') {
		return importAll(require.context('../version07/00/english', false, /\.(jpg|png)$/));
	} else if(number === '08' &&  subVersion === "00" && language === 'english') {
		return importAll(require.context('../version08/00/english', false, /\.(jpg|png)$/));
	} else if(number === '08' && subVersion === "00" &&  language === 'bengali') {
		return importAll(require.context('../version08/00/bengali', false, /\.(jpg|png)$/));
	} else if(number === '08' && subVersion === "00" &&  language === 'spanish') {
		return importAll(require.context('../version08/00/spanish', false, /\.(jpg|png)$/));
	}
}

const getData = ({number,market, subVersion, language, event }) => { 
	let versionNumber = number;
	
	let data = {
		"next": "Next >",
		"back": "< Back",
		"done": "Done",
		"languageButton1": "বাংলা",
		"languageButton1Translate": "bengali",
		"languageButton2": "Español",
		"languageButton2Translate": "spanish",
		"screens": getScreens(number,market, subVersion, language ),
		"mixpanelPageview": `DriverTraining${versionNumber}${market}${event} - Web View`,
		"mixpanelNextClick": `DriverTraining${versionNumber}${market}${event} - NextClick`,
		"mixpanelBackClick": `DriverTraining${versionNumber}${market}${event} - BackClick`,
		"mixpanelDoneClick": `DriverTraining${versionNumber}${market}${event} - DoneClick`,
		"mixpanelRestartClick": `DriverTraining${versionNumber}${market}${event} - RestartClick`,
		"mixpanelLanguage1": `DriverTraining${versionNumber}${market}${event} - BengaliLanguageClick`,
		"mixpanelLanguage2": `DriverTraining${versionNumber}${market}${event} - SpanishLanguageClick`
	}

	if(language === "bengali"){
		data = {
			"next": "পরবর্তী >",
			"back": "< পেছনে",
			"done": "সম্পন্ন",
			"languageButton1": "English",
			"languageButton1Translate": "english",
			"languageButton2": "Español",
			"languageButton2Translate": "spanish",
			"screens": getScreens(number,market, subVersion, "bengali" ),
			"mixpanelPageview": `DriverTraining${versionNumber}${market}${event} - Web View - Bengali`,
			"mixpanelNextClick": `DriverTraining${versionNumber}${market}${event} - NextClick - Bengali`,
			"mixpanelBackClick": `DriverTraining${versionNumber}${market}${event} - BackClick - Bengali`,
			"mixpanelDoneClick": `DriverTraining${versionNumber}${market}${event} - DoneClick - Bengali`,
			"mixpanelRestartClick": `DriverTraining${versionNumber}${market}${event}  - RestartClick - Bengali`,
			"mixpanelLanguage1": `DriverTraining${versionNumber}${market}${event} - EnglishLanguageClick`,
			"mixpanelLanguage2": `DriverTraining${versionNumber}${market}${event} - SpanishLanguageClick`
		}
	} else if(language === "spanish"){
		data = {
			"next": "Siguiente >",
			"back": "< Anterior",
			"done": "Terminar",
			"languageButton1": "বাংলা",
			"languageButton1Translate": "bengali",
			"languageButton2": "English",
			"languageButton2Translate": "english",
			"screens": getScreens(number,market, subVersion, language),
			"mixpanelPageview": `DriverTraining${versionNumber}${market}${event} - Web View - Spanish`,
			"mixpanelNextClick": `DriverTraining${versionNumber}${market}${event} - NextClick - Spanish`,
			"mixpanelBackClick": `DriverTraining${versionNumber}${market}${event} - BackClick - Spanish`,
			"mixpanelDoneClick": `DriverTraining${versionNumber}${market}${event} - DoneClick - Spanish`,
			"mixpanelRestartClick": `DriverTraining${versionNumber}${market}${event} - RestartClick - Spanish`,
			"mixpanelLanguage1": `DriverTraining${versionNumber}${market}${event} - BengaliLanguageClick`,
			"mixpanelLanguage2": `DriverTraining${versionNumber}${market}${event} - EnglishLanguageClick`
		}
	}
	return data; 
}

export default getData;